<script lang="ts" setup>
  import { useSiteStore } from '~/stores/site.store';
  import Tag from 'primevue/tag';
  import { QuestionFilledIcon } from '~/components/design-system/icons/index';
  const site = useSiteStore();
  const theme = useColorMode();
</script>
<template>
    <Tag
      class="w-[56px] h-[40px] py-0 px-1 ml-2 border-[2px] dark:bg-dark-700 dark:border-gold-500 bg-gold-500 border-dark-700 rounded-lg text-pretty"
    >
      <span 
        class="text-[12px] font-bold mt-0 relative block	dark:text-gold-500 text-dark-700"
      >
        {{ $t('limited-access') }}
      </span>
    </Tag>
    <QuestionFilledIcon 
      :fill="theme.preference"
      class="ml-2 cursor-pointer"
      @click="site.activateModal('accountRestricted')" 
    />
</template>