<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import Button from '~/components/design-system/button.vue';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import { BanknotesIcon } from '@heroicons/vue/24/outline';
import { useSiteStore } from '~/stores/site.store.js';

interface IUserBalances {
  displayBalanceToggle?: boolean;
  displayRefreshBalance?: boolean;
  displayAccountBalanceToggle?: boolean;
  displayCashBalance?: boolean;
  displayBonusBalance?: boolean;
  displayTotalBalance?: boolean;
  displayDepositButton?: boolean;
  depositButtonStyle?: 'full' | 'icon';
  depositButtonFallback?: 'icon' | 'text';
  stretch?: boolean;
  accountOptionsModalStyle?: boolean;
  backgroundColor?:
    | 'bg-primary-layer'
    | 'bg-primary-layer-alternative'
    | 'bg-base';
  singleLine?: boolean;
  displayTruncatedBalance?: boolean;
}
const auth = useAuthStore();
const site = useSiteStore();

const { $enabled } = useNuxtApp();

const props = withDefaults(defineProps<IUserBalances>(), {
  displayBalanceToggle: false,
  displayRefreshBalance: false,
  displayAccountBalanceToggle: false,
  displayCashBalance: false,
  displayBonusBalance: false,
  displayTotalBalance: false,
  displayDepositButton: false,
  depositButtonStyle: 'icon',
  depositButtonFallback: 'icon',
  stretch: false,
  accountOptionsModalStyle: false,
  backgroundColor: 'bg-primary-layer-alternative',
  singleLine: false,
  displayTruncatedBalance: false,
});

const totalBalance = computed(() => {
  return (
    (auth?.accountBalances?.cashBalance || 0) +
    (auth.getActiveBonus?.totalAwardedBonusAmount ||
      auth?.accountBalances?.playThroughBalance ||
      0)
  );
});

const loading = computed(
  () =>
    !auth.accountBalances?.cashBalance &&
    auth.accountBalances?.cashBalance !== 0,
);

const computedGridCols = computed(() => {
  let returnClass = props.stretch ? 'w-full' : '';
  const checks = [
    props.displayCashBalance && $enabled('myaccount.cashbalance'),
    props.displayBonusBalance && $enabled('myaccount.bonusbalance'),
    props.displayTotalBalance && $enabled('myaccount.totalbalance'),
  ].reduce((prev, curr) => {
    if (!!curr) return [...prev, curr];
    return [...prev];
  }, []);
  switch (checks.length) {
    case 1:
      return `${returnClass} grid-cols-1`;
    case 2:
      return `${returnClass} grid-cols-2`;
    case 3:
      return `${returnClass} grid-cols-3`;
  }
});

const breakpoint = useBreakpoint();
const state = reactive({
  renderSelector: breakpoint.sm,
});
</script>

<template>
  <div
    v-if="
      auth.getDocumentVerificationStatus ||
      !site.getFeatureFlags.get('myaccount.enablecompliance')
    "
    class="flex justify-center items-center gap-1"
    :class="accountOptionsModalStyle ? 'sm:mt-2' : ''"
  >
    <Button
      v-if="displayBalanceToggle"
      type="transparent"
      @click="auth.toggleBalances(!auth.displayAccountBalances)"
    >
      <EyeSlashIcon v-if="auth.displayAccountBalances" class="w-4" />
      <EyeIcon v-else class="w-4" />
    </Button>
    <div class="grid gap-1" :class="computedGridCols">
      <UserBalance
        v-if="displayCashBalance && $enabled('myaccount.cashbalance')"
        :loading="loading"
        :label="
          $t(
            accountOptionsModalStyle && state.renderSelector
              ? 'cash.balance'
              : 'cash-balance',
          )
        "
        :hide-balance="!auth.displayAccountBalances"
        :amount="auth?.accountBalances?.cashBalance"
        :error="auth.accountBalances?.cashBalance < 0"
        :toggle-refresh-balances="displayRefreshBalance"
        :toggle-balance-modal="displayAccountBalanceToggle"
        :is-account-modal="accountOptionsModalStyle"
        :background-color="backgroundColor"
        :single-line="singleLine"
        :truncate-balance="displayTruncatedBalance"
      />
      <UserBalance
        v-if="displayBonusBalance && $enabled('myaccount.bonusbalance')"
        :loading="loading"
        :label="$t('bonus-balance')"
        :hide-balance="!auth.displayAccountBalances"
        :amount="
          auth.activeBonus?.totalAwardedBonusAmount ||
          auth?.accountBalances?.playThroughBalance
        "
        :error="auth.activeBonus?.totalAwardedBonusAmount < 0"
        :toggle-refresh-balances="displayRefreshBalance"
        :toggle-balance-modal="displayAccountBalanceToggle"
        :is-account-modal="accountOptionsModalStyle"
        :background-color="backgroundColor"
        :single-line="singleLine"
        :truncate-balance="displayTruncatedBalance"
      />
      <UserBalance
        v-if="displayTotalBalance && $enabled('myaccount.totalbalance')"
        :loading="loading"
        :label="$t('total-balance')"
        :hide-balance="!auth.displayAccountBalances"
        :amount="totalBalance"
        :error="totalBalance < 0"
        :toggle-refresh-balances="displayRefreshBalance"
        :toggle-balance-modal="displayAccountBalanceToggle"
        :is-account-modal="accountOptionsModalStyle"
        :background-color="backgroundColor"
        :single-line="singleLine"
        :truncate-balance="displayTruncatedBalance"
      />
    </div>
    <Button
      v-if="displayDepositButton"
      type="other"
      class="flex-center"
      :class="{
        'w-full max-w-[110px]': depositButtonStyle === 'full',
        'w-full max-w-[40px] max-h-[40px]': depositButtonStyle !== 'full',
      }"
      @click="auth.setAccountModalPage('deposit')"
    >
      <div
        v-if="depositButtonStyle === 'full'"
        :class="[
          { 'hidden md:block': depositButtonFallback === 'icon' },
          'ml-1 mr-1',
        ]"
      >
        {{ $t('deposit') }}
      </div>
      <BanknotesIcon
        :class="[
          {
            'hidden md:block':
              depositButtonFallback === 'text' && depositButtonStyle === 'full',
          },
        ]"
        class="w-5"
      />
    </Button>
  </div>
  <Button
    v-else-if="site.getFeatureFlags.get('myaccount.enablecompliance')"
    class="text-sm"
    @click="auth.setAccountModalPage('document-verification')"
  >
    <div class="text-wrap text-center w-full">
      {{ $t('complete-your-account') }}
    </div>
  </Button>
</template>

<style scoped lang="scss"></style>
