<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import ThemedImage from '~/components/design-system/themed-image.vue';

const imagesPath: Record<string, string> = {
  dark: useImagePath(
    '/medialibraries/Jackpotcity.co.za/content-pages/404_light.png',
  ),
  light: useImagePath(
    '/medialibraries/Jackpotcity.co.za/content-pages/404_dark.png',
  ),
};

function routeHomeAndClear() {
  clearError({ redirect: '/' });
}
</script>

<template>
  <div class="flex flex-col justify-between items-center p-8 container">
    <ThemedImage
      :dark="imagesPath.dark"
      :light="imagesPath.light"
      image-class="w-[60vw] md:w-[50vw] lg:w-[30vw] mx-auto"
    />
    <div class="text-base-priority">
      <div
        class="flex flex-col items-center text-center text-xl md:text-3xl font-normal line-height-1 pt-8"
      >
        <div class="font-bold">
          {{ $t('jpc-page-not-found.title') }}
        </div>
        <div class="font-semibold">
          {{ $t('jpc-page-not-found.subtitle') }}
        </div>
      </div>

      <div
        class="text-lg md:text-xl font-normal line-height-3 mt-4 text-center"
      >
        <p>{{ $t('jpc-page-not-found.description-title') }}</p>
        <div class="w-full">
          <ul class="mt-4 text-left mx-auto w-max">
            <li>{{ $t('jpc-page-not-found.description-bullet-one') }}</li>
            <li>{{ $t('jpc-page-not-found.description-bullet-two') }}</li>
          </ul>
        </div>
      </div>

      <div class="p-6 flex justify-center">
        <Button
          @click="routeHomeAndClear()"
          class="mx-auto"
          element-name="four-o-four-back-button"
        >
          {{ $t('back-button') }}
        </Button>
      </div>
    </div>
  </div>
</template>
