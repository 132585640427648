import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import type { IFavouriteResponse } from '~/composables/services/useGameService';

export default async function ({ game }): Promise<IFavouriteResponse> {
  const { $gameService } = useServices();
  const auth = useAuthStore();
  const site = useSiteStore();

  const route = useRoute();

  return await $gameService.addGameFavourites({
    channel: 'WebDesktop',
    languageCode: 'en-US',
    vertical: site.sitemapMap.get(
      `${!!route.params?.contentType ? '/' + String(route.params?.contentType) : '/'}`,
    ).Mapping,
    currency: 'USD',
    token: auth.access_token,
    game: game,
  });
}
