<script setup>
import Button from '~/components/design-system/button.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';

const { $t, $mobileNumberRegex } = useNuxtApp();
const site = useSiteStore();
const route = useRoute();
const router = useRouter();
const { $authService, $walletService, $toastService, $storageService } =
  useServices();

const fetchFavoriteGames = useGetFavorites;
const prepareMobileNumber = usePrepareMobileNumber;

const activeLoader = ref(false);
const errorCode = ref('');
const form = ref();

const deviceInfo = $storageService.getUniversalStorage({
  key: 'device-info',
});

async function login() {
  activeLoader.value = true;
  // send GA tracking response for login init
  trackingMethods.login(form?.value?.data['username']);
  const username = prepareMobileNumber(form?.value?.data['username']);
  const request = {
    username: username,
    password: form?.value?.data['password'],
    countryCode: site.region,
    sessionMetadata: {
      sessionTrackingToken: deviceInfo?.hash,
      uip: deviceInfo?.IpAddress,
    },
  };
  await $authService
    .login(request)
    .then(async (data) => {
      if (!data?.isSuccessful) {
        checkErrors(data.error);
        // send GA tracking response for login failure
        trackingMethods.loginFailure(username, data.error.message);
      } else {
        await $walletService.fetchBalances();
        routeToCurrentGame();
      }
      setActiveLoaderToFalse();
      return data;
    })
    .finally(async () => {
      trackingMethods.loginComplete(username);
    })
    .catch((e) => {
      errorCode.value = 'something-went-wrong';
      setActiveLoaderToFalse();
    });
}

const setActiveLoaderToFalse = () => {
  return (activeLoader.value = false);
};

const routeToCurrentGame = () => {
  if (route.query.redirect) {
    const splitUrl = route.query.redirect.split('?');
    const queryparams = new URLSearchParams(splitUrl[1]);
    router.push({ path: splitUrl[0], query: Object.fromEntries(queryparams) });
  }
  if (route.query.freePlay) {
    const clonedQuery = Object.assign({}, route.query);
    delete clonedQuery.freePlay;
    router.replace({ query: clonedQuery });
  }
};

const checkErrors = (error) => {
  console.error(error);
  if (!!error) {
    errorCode.value = error.code;
  }
};

const display = computed(() => activeLoader.value);

const forgotPassword = () => {
  // Password reset view for logged out user
  site.activateModal('passwordResetStepOne');
};
</script>
<template>
  <div
    class="login-container relative flex flex-col h-max-content"
    @keyup.enter="login()"
  >
    <LazyUserInterfaceGenericLoader
      v-if="display"
      :key="activeLoader"
      container
    />
    <Vueform
      ref="form"
      :float-placeholders="true"
      :endpoint="false"
      class="mx-3 my-4"
    >
      <template #empty>
        <TextElement
          name="username"
          input-type="number"
          :attrs="{ inputmode: 'numeric' }"
          :placeholder="$t('placeholder.primaryMobileNumber')"
          :floating="$t('primaryMobileNumber')"
          :rules="['required', $mobileNumberRegex]"
          :messages="{ regex: $t('formErrors.primaryMobileNumber') }"
          add-class="mb-3"
        >
          <template #addon-before>
            <div
              class="bg-light-200 dark:bg-dark-800 flex flex-row justify-center items-center border-r border-solid std-border-color w-full h-full px-2"
            >
              {{ site.getDialingCode }}
            </div>
          </template>
        </TextElement>
        <VueformPasswordElement
          :key="'password'"
          :name="'password'"
          :rules="[
            'required',
            'regex:/^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$/',
          ]"
          :messages="{ regex: $t('formErrors.password') }"
          :placeholder="$t('placeholder.' + 'password')"
          :floating="$t('password')"
          :field-name="$t('password')"
          :display-feedback="false"
        />
      </template>
    </Vueform>
    <GenericError class="mb-3" v-if="errorCode" state="danger">
      <div v-html="$t(`jpc-login-errors-${errorCode}`)" class="flex" />
    </GenericError>
    <div class="p-3 pt-4 bg-light-200 dark:bg-dark-800 login-action">
      <div
        class="rounded-lg flex justify-between items-center max-w-full px-2 mb-2 bg-light-50 dark:bg-dark-900 text-xs"
      >
        <p class="my-3 dark:text-white text-black">
          {{ $t('dont-have-account') }}
        </p>
        <div class="flex flex-row flex-nowrap items-center">
          <p
            class="my-3 mr-1 dark:text-primary-blue-100 text-primary-blue-300 text-xs flex flex-row cursor-pointer"
            @click="site.activateModal('register')"
          >
            {{ $t('jpc-register') }}
          </p>
          <ChevronRightIcon
            class="h-4 text-xs dark:text-primary-blue-100 text-primary-blue-300 font-light"
          />
        </div>
      </div>
      <div class="min-w-full mb-2">
        <Button
          :disabled="!(form?.validated && !form?.invalid)"
          class="text-white uppercase text-base font-bold text-center justify-center items-center min-w-full px-2 py-2 mt-1 mb-1"
          group="primary"
          @click="login()"
        >
          {{ $t('login') }}
        </Button>
      </div>
      <p
        class="dark:text-primary-blue-100 text-primary-blue-300 text-xs text-center cursor-pointer my-0"
        @click="forgotPassword()"
      >
        {{ $t('forgot-password') }}
      </p>
    </div>
  </div>
</template>
<style lang="scss">
.login-container input.bg-transparent {
  @apply bg-light-200 dark:bg-dark-800;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  .login-container & {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #2f2f37;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #f0f0f2 !important;
    .dark & {
      -webkit-text-fill-color: #ffffff;
      box-shadow: inset 0 0 20px 20px #202126 !important;
    }
  }
}
</style>
