<script setup lang="ts">
import AppleIcon from '../design-system/icons/AppleIcon.vue';
import AppDownload from '~/components/user-interface/app-download.vue';

const HuaweiIcon = useImagePath(
  '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/huawei_icon.svg',
);
const AndroidIcon = useImagePath(
  '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/android_icon.svg',
);

const { $storageService } = useServices();
const devicesList: string[] = ['iPhone', 'Android', 'Huawei'];

interface IAppDownloadProps {
  type?: string;
  background?: string; //background of the link divs
  direction?: 'vertical' | 'horizontal';
}

const props = withDefaults(defineProps<IAppDownloadProps>(), {
  direction: 'horizontal',
});

const deviceName = computed(() => {
  const currentDeviceName: string = devicesList.find(
    (device) => navigator.userAgent.toString().indexOf(device) > -1,
  );

  return !!currentDeviceName ? currentDeviceName : 'Desktop';
});

const displayApple = computed(
  () => deviceName.value === 'iPhone' || deviceName.value === 'Desktop',
);
const displayAndroid = computed(
  () =>
    deviceName.value === 'Android' ||
    deviceName.value === 'Desktop' ||
    deviceName.value === 'Huawei',
);

function downloadApp() {
  const btag = $storageService.getCookie({ key: 'BTAGCOOKIE' });
  window.open(
    `https://info.jpc.africa/api/v1/JackpotCityAppDownload/?btag=${
      !!btag ? btag : 'P97425-PR26622-CM89032-TS1984022'
    }`,
  );
}
</script>

<template>
  <div
    class="flex flex-center flex-wrap gap-2"
    :class="direction === 'vertical' ? 'flex-col w-full' : 'flex-row'"
    @click="downloadApp()"
  >
    <AppDownload
      v-if="displayApple"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <AppleIcon element-name="appleIcon" />
      </template>
      <template #appType>
        {{ $t('app-store') }}
      </template>
    </AppDownload>
    <AppDownload
      v-if="displayAndroid"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <img
          element-name="androidIcon"
          :src="AndroidIcon"
          alt="Jackpotcity Android App"
        />
      </template>
      <template #appType>
        {{ $t('android') }}
      </template>
    </AppDownload>
    <AppDownload
      v-if="displayAndroid"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <img
          element-name="huaweiIcon"
          :src="HuaweiIcon"
          alt="Jackpotcity Huawei App"
        />
      </template>
      <template #appType>
        {{ $t('app-gallery') }}
      </template>
    </AppDownload>
  </div>
</template>
<style lang="scss" scoped></style>
