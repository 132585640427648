type TMobileOS = 'Windows Phone' | 'iOS' | 'Android' | 'Huawei' | 'unknown';
interface IMessageEvent {
  message: string;
  data: { Action: string; Value: string };
}

const getMobileOperatingSystem = (): TMobileOS => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/Android|android/i.test(userAgent)) {
    return 'Android';
  }
  if (
    /iPad|iPhone|iPod|AppleWebKit|Safari/.test(userAgent) &&
    !window.MSStream
  ) {
    return 'iOS';
  }
  return 'unknown';
};

const messageHandler = (event: IMessageEvent) => {
  const appType = getMobileOperatingSystem();

  switch (appType) {
    case 'iOS':
      window?.webkit?.messageHandlers?.PostData?.postMessage(
        JSON.stringify(event.data),
      );
      break;
    case 'Android':
    case 'Huawei':
      window?.Data?.postData(JSON.stringify(event.data));
      break;
    default:
      window.postMessage(event.data);
  }
};

export function useLaunchGamePostMessage(gameId: number | string) {
  return messageHandler({
    message: 'LaunchGame',
    data: { Value: String(gameId), Action: 'LaunchGame' },
  });
}
