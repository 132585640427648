import type { ISitemapRemap } from '~/interfaces/dto/sitemap/response';

export default function useWindowFunctions() {
  const { $pinia } = useNuxtApp();
  const { $toastService, $promotionService } = useServices();
  const siteStore = useSiteStore($pinia);
  const auth = useAuthStore($pinia);
  window.navigate = (path: string) => {
    navigateTo(path);
  };
  window.openModal = (modal: string) => {
    switch (modal.toLocaleLowerCase()) {
      case 'login':
        if (!auth.loggedIn) {
          siteStore.activateModal(modal);
        }
        break;

      case 'register':
        if (!auth.loggedIn) {
          siteStore.activateModal(modal);
        }
        break;

      case 'reset-password':
        if (auth.loggedIn) {
          auth.setAccountModalPage('password-management');
        } else {
          siteStore.activateModal('passwordResetStepOne');
        }
        break;

      case 'deposit':
        if (auth.loggedIn) {
          auth.setAccountModalPage('deposit');
        } else {
          siteStore.activateModal('login');
        }
        break;

      case 'withdrawal':
        if (auth.loggedIn) {
          auth.setAccountModalPage('withdrawal');
        } else {
          siteStore.activateModal('login');
        }
        break;

      default:
        break;
    }
  };
  window.displayLiveChat = () => {
    siteStore.toggleFeature({ feature: 'displayLiveChat' });
  };
  window.addEventListener('selectstart', function (e) {
    e.preventDefault();
  });

  window.promotions = {
    addTopNavigation: (sitemapEntry: Partial<ISitemapRemap>) => {
      siteStore.addToSiteMap({
        ...sitemapEntry,
        Navigation: { top: true, footer: false, left: false },
        MobileApp: {
          enabled: true,
          component: 'WebViewComponent',
          content: null,
        },
      });
    },
    triggerToast: (path: string) => {
      $toastService.toastService.generateToast(path);
    },
    revalidateSpinCounts: () => {
      $promotionService.getGenericWheelCount();
    },
  };
}
