<script setup lang="ts">
import { mergeSort } from '~/utilities/data-transformers';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useAuthStore } from '~/stores/auth.store';
import dayjs from 'dayjs';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import Button from '../design-system/button.vue';
import { brandIds, captcha } from '~/assets/data/config';
import LockIcon from '../design-system/icons/LockIcon.vue';

import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/vue/24/outline';
import type { IInfoResponse } from '~/interfaces/dto/marketing/response';

const route = useRoute();
const colorMode = useColorMode();
interface IRegProps {
  element: string;
  inputMode?: string;
  name: string;
  label: string;
  required?: boolean;
  rules?: string[] | string;
  messages?: any;
  clickFunction: void | any;
  items?: string[] | Record<string, unknown>;
  defaultVal?: string;
}

const { $t, $enabled, $underageCheck, $mobileNumberRegex } = useNuxtApp();
const site = useSiteStore();
const authStore = useAuthStore();

const {
  $registrationService,
  $authService,
  $complianceService,
  $storageService,
} = useServices();

const formError = ref(null);
const formLoading = ref(false);

const dobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-8]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';
const leapYearDobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-9]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';

/** Vueform variables  */
const form = ref();
const formSteps = ref();
const pageOne = ref();
const pageTwo = ref();
const dateOfBirth = ref();
const progressBarFields = ref();
const vfPageOne: Ref<string[]> = ref([]);
const vfPageTwo: Ref<string[]> = ref([]);
const isReferralInputActive = ref(false);

const formModel = ref(); //vueform model
const formattedTemplate: Ref<Partial<IRegProps>[]> = ref([]);

/**Vueform Prop Variables */
const bdayLock = ref(false);
const minAge = new Date();
minAge.setFullYear(minAge.getFullYear() - 18);
const maxAge = new Date();
maxAge.setFullYear(maxAge.getFullYear() - 101);

/**  Computeds */

const calendarIcon = computed(() => {
  return bdayLock.value ? LockIcon : CalendarIcon;
});

const progressBar = computed(() => {
  let validFields = 0;
  //account for when progressBarFields is null
  if (!progressBarFields.value) {
    return 0;
  }
  //get names of all elements in form
  const names = Object.keys(progressBarFields.value);
  /**
   * iterate the object and check if:
   * 1.) the value has been modified (dirty)
   * 2.) the value is valid
   * Then incremenet validFields int
   */
  names.forEach((name) => {
    const el = progressBarFields.value[name];
    if (el.dirty && !el.invalid) {
      validFields++;
    }
  });
  return validFields / names.length;
});

// Clear IDNumber when switching IDNumberType
function changeIdType() {
  form.value.el$('idNumber').messageBag.clearAppended();
  form?.value?.el$('idNumber')?.update('');
  if (form?.value?.el$('idNumberType').data.idNumberType === 'Passport') {
    bdayLock.value = false;
  }
}

function validateIdField() {
  if (form?.value?.el$('idNumberType').data.idNumberType === 'Passport') {
    validatePassport();
  } else {
    validateIdCard();
  }
}

function validateIdCard() {
  const modelVal = form?.value?.data?.idNumber;
  const regExpression = '^\\d{13}$';
  const regex = new RegExp(regExpression);
  const validId = regex.test(modelVal);

  if (validId) {
    // assignModelValid
    form.value.el$('idNumber').messageBag.clearAppended();
    const regExpression =
      modelVal.substring(0, 2) % 4 !== 0 ? dobRegex : leapYearDobRegex;
    const regex = new RegExp(regExpression);
    const validDob = regex.test(modelVal);

    if (validDob) {
      //lock DOB
      bdayLock.value = true;
      //generate DOB
      const { year, month, day } =
        $registrationService.generateDateOfBirthFromID(modelVal);
      const formattedDate = `${year}-${month}-${day}`;
      form?.value?.elements$?.dateOfBirth.update(new Date(formattedDate));
      const date = dayjs();
      const difference = date.diff(formattedDate, 'year');

      if (difference < 18) {
        form.value
          .el$('idNumber')
          .messageBag.append($t('formErrors.idNumber-underage'));
      } else {
        form.value
          .el$('idNumber')
          .messageBag.remove($t('formErrors.idNumber-underage'));
      }
    } else {
      //unlock DOB
      bdayLock.value = false;
      //assignModelError invalid DOB
      form.value
        .el$('idNumber')
        .messageBag.append($t('formErrors.idNumber-notValid'));
    }
  } else {
    //Invalid ID, too short or not numbers
    bdayLock.value = false;
    //assignModelError invalid ID
    form.value
      .el$('idNumber')
      .messageBag.append($t('formErrors.idNumber-notValid'));
  }
}

function validatePassport() {
  const modelVal = form?.value?.data?.idNumber;
  const regExpression = '^[a-zA-Z0-9]{3,20}$';
  const regex = new RegExp(regExpression);
  const isValid = ref(false);

  isValid.value = modelVal === null ? false : regex.test(modelVal); //checking if passport val is empty
  if (isValid.value) {
    form.value.el$('idNumber').messageBag.clearAppended();
  } else {
    form.value.el$('idNumber').messageBag.append($t('formErrors.Passport'));
  }
}

//Focus input when field already exists error occurs
function focusInputField(inputName: string) {
  setTimeout(() => {
    document.getElementById(inputName).focus();
  }, 250);
}

/** Reg Helper functions */
function formatTemplate(template: any) {
  //Sort fields by sortIndex per page
  template.forEach((page) => {
    page.templateFields.sort((a, b) => a.sortIndex - b.sortIndex);
  });

  try {
    const sortedPages = mergeSort(template, 'sortIndex');
    const firstPage = sortedPages[0].templateFields;
    const secondPage = sortedPages[1]?.templateFields;

    vfPageOne.value = firstPage.map((field) => {
      return field.propertyName;
    });

    vfPageTwo.value = secondPage?.map((field) => {
      return field.propertyName;
    });

    if ($enabled('website.enablerecaptcha')) {
      if (vfPageTwo.value?.length > 0) vfPageTwo.value.push('captcha');
      else vfPageOne.value.push('captcha');
    }
    const fullTemplate = [...firstPage, ...(secondPage ?? [])];

    //go through each field and format it for vueform
    fullTemplate.forEach((field) => {
      let obj: Partial<IRegProps> = {
        name: field.propertyName,
        label: $t(
          `${field.templateFieldAttributes?.titleKey || field.propertyName}-${
            site.getRegionCode
          }`,
          field.templateFieldAttributes?.titleKey || field.propertyName,
        ),
        required: field.templateFieldAttributes?.required || false,
        rules: calculateRegex(
          field.templateFieldAttributes.required,
          field?.regExpression,
          field?.propertyName,
        ),
        messages: field?.regExpression
          ? { regex: $t('formErrors.' + field.propertyName) }
          : {},
      };
      switch (field.templateFieldControlId) {
        case '00000000-0000-0000-da7a-000000200011':
          Object.assign(obj, {
            element: 'mobileNumber',
            inputMode: 'number',
          });
          break;

        case '00000000-0000-0000-da7a-000000200001':
          Object.assign(obj, {
            element: 'inputField',
            inputMode: 'text',
          });
          break;
        case '00000000-0000-0000-da7a-000000200014':
          Object.assign(obj, {
            element: 'email',
            inputMode: 'email',
          });
          break;

        case '00000000-0000-0000-da7a-000000200008':
          Object.assign(obj, {
            element: 'password',
          });
          break;

        case '00000000-0000-0000-da7a-000000200003':
          Object.assign(obj, {
            element: 'dropdown',
            items: prepareDropdownItems(field.items, field.propertyName),
          });
          break;

        case '00000000-0000-0000-da7a-000000200009':
          obj = {
            element: 'dob',
            name: field.propertyName,
          };
          break;

        case '00000000-0000-0000-da7a-000000200006':
          Object.assign(obj, {
            element: 'checkbox',
            clickFunction: setClickFunction(field.propertyName),
          });
          break;

        case '00000000-0000-0000-da7a-000000200015':
          Object.assign(obj, {
            element: 'dropdownInput',
          });
          break;

        case '00000000-0000-0000-da7a-000000200005':
          Object.assign(obj, {
            element: 'radioList',
            items: prepareDropdownItems(field.items, field.propertyName),
            defaultVal: field?.templateFieldAttributes?.defaultValue || '',
          });
          break;
        default:
          break;
      }

      formattedTemplate.value.push(obj);
    });
  } catch (e) {
    formLoading.value = false;
    formError.value = $t('registration-failed');
    console.error(e);
  }
}

function calculateRegex(required?: boolean, regex?: string, property?: string) {
  let regexArr = [];
  if (required) regexArr.push('required');
  if (!!regex) {
    if (!!property && property === 'primaryMobileNumber') {
      regexArr.push($mobileNumberRegex);
    } else {
      regexArr.push(`regex:/${regex}/`);
    }
  }
  if (regexArr.length > 0) return regexArr;
  return '';
}

function prepareDropdownItems(list: string[], property) {
  //Temp until reg template changes
  if (property === 'language') {
    return site.getConfig.cultures.reduce(
      (acc, curr) => ({ ...acc, [curr.cultureCode]: curr.translation }),
      {},
    );
  }
  if (property === 'welcomeOffer') {
    return list.map(
      (item) => ({
        value: item,
        label: $t(`jpc-reg-radio-${item}`),
      }),
      {},
    );
  }
  if (property === 'idNumberType') {
    return list.reduce(
      (key, value) => ({ ...key, [value]: $t(`jpc-reg-${value}`) }),
      {},
    );
  } else {
    return list.reduce(
      (key, value) => ({
        ...key,
        [value.replaceAll(' ', '').replaceAll('-', '')]: $t(
          `jpc-reg-${value.replaceAll(' ', '-')}`,
        ),
      }),
      {},
    );
  }
}

function setClickFunction(propertyname: string) {
  switch (propertyname) {
    case 'agreeToAll':
      return agreeToAll;
      break;

    default:
      return;
      break;
  }
}

function convertDate(date) {
  return new Date(date).toISOString();
}

const prepareMobileNumber = usePrepareMobileNumber;

async function register() {
  form.value.messageBag.clearAppended();
  formModel.value = { ...form.value.data };
  if (formModel.value['dateOfBirth'])
    formModel.value['dateOfBirth'] = convertDate(
      formModel.value['dateOfBirth'],
    );

  const deviceInfo = $storageService.getUniversalStorage<IInfoResponse>({
    key: 'device-info',
  });

  const additionalProps = {
    signUpCode: '',
    countryCode: site.region,
    currencyCode: site.getCurrencyCode,
    dialingCode: site.getDialingCode,
    brandId: brandIds[site.getRegionCode],
    language: 'English',
    gender: 'Male',
    languageCode: 'EN',
    cultureCode: 'Un',
    communicationChannel: 'Web',
    bannerTag:
      $storageService.getCookie({ key: 'BTAGCOOKIE' }) ||
      'P97425-PR26623-CM89033-TS1984022',
    sessionToken: $storageService.getCookie({ key: 'ST' }),
    masterToken: $storageService.getCookie({ key: 'MT' }),
    btagcookie:
      $storageService.getCookie({ key: 'BTAGCOOKIE' }) ||
      'P97425-PR26623-CM89033-TS1984022',
    st: $storageService.getCookie({ key: 'ST' }),
    mt: $storageService.getCookie({ key: 'MT' }),
    originalQueryString: $storageService.getCookie({
      key: 'OriginalQueryString',
    }),
    username: prepareMobileNumber(formModel?.value?.primaryMobileNumber),
    primaryMobileNumber: prepareMobileNumber(
      formModel.value.primaryMobileNumber,
    ),
    idNumberType: formModel?.value?.idNumberType?.replaceAll(' ', ''),
    sessionMetadata: {
      welcomeOffer: formModel?.value?.welcomeOffer || '',
      sessionTrackingToken: deviceInfo?.hash,
      uip: deviceInfo?.IpAddress,
    },
  };

  formError.value = '';
  formLoading.value = true;
  // send GA tracking method for register request init
  await trackingMethods.register(formModel.value['primaryMobileNumber']);
  const actionId = window?.dtrum?.enterAction(
    `registration-[${$storageService.getCookie({ key: 'BTAGCOOKIE' })}]`,
    null,
    null,
    route.fullPath,
  );
  await $authService
    .register({ ...formModel.value, ...additionalProps })
    .then((data) => {
      if (data?.isSuccessful) {
        //Successful reg
        $storageService.removeCookie({ key: 'signup-code' });
        trackingMethods.registerComplete(
          formModel.value['primaryMobileNumber'],
        );
        window?.dtrum?.leaveAction(actionId);
        if (!$storageService.getCookie({ key: 'BTAGCOOKIE' })) {
          window?.dtrum?.reportCustomError(
            'bannertags',
            `Account ${formModel.value['primaryMobileNumber']} registered with no btag set`,
            null,
            false,
          );
        }
        //Send welcome offer opt in
        if (formModel?.value?.welcomeOffer === 'true') {
          authStore.setWelcomeOffer(true);
        }

        if (site.getFeatureFlags.get('myaccount.enablecompliance')) {
          //ZA Post Reg Flow

          if (formModel.value['idNumberType'] === 'Passport') {
            //if passport go to welcome offer
            authStore.setPartialPostReg(true);
            site.activateModal('registrationWelcomeOffer');
          } else {
            if (!data?.data?.complianceResponse?.isValidId) {
              //minor or deceased
              formError.value = $t('deceased-or-minor-reg');
              trackingMethods.registerFailure(
                formModel.value['primaryMobileNumber'],
                'Deceased or Minor Individual Reg',
              );
              throw 'deceased-or-minor-reg';
            } else {
              if (data?.data?.complianceResponse?.complianceStatus > 0) {
                //auto fica'd
                authStore.setPartialPostReg(false);
                site.activateModal('registrationWelcomeOffer');
              } else {
                //call names to verify
                $complianceService.getPostRegNames().then((data) => {
                  if (data?.isSuccessful) {
                    site.activateModal('registrationVerifyName'); //cpb returned names list to verify
                  } else {
                    //cpb returned null nav to welcome offer
                    authStore.setPartialPostReg(true);
                    site.activateModal('registrationWelcomeOffer');
                  }
                });
              }
            }
          }
        } else {
          //ROA Flow
          site.activateModal('registrationWelcomeOffer');
        }
      } else {
        //unsuccessful reg
        const error = data?.error;
        //if error is null default error message otherwise check if kentico string exists
        formError.value = !error
          ? $t('registration-failed')
          : $t(`jpc-reg-error.${error?.code}`) !==
              `jpc-reg-error.${error?.code}`
            ? $t(`jpc-reg-error.${error?.code}`)
            : error?.message;

        trackingMethods.registerFailure(
          formModel.value['primaryMobileNumber'],
          error?.message,
        );
        // Duplicate fields handling
        switch (error?.code) {
          case 132002:
          case 153008:
            formSteps.value.previous();
            focusInputField('primaryMobileNumber');
            form.value
              .el$('primaryMobileNumber')
              .messageBag.append($t('number-in-use'));
            break;
          case 132035:
            formSteps.value.previous();
            focusInputField('email');
            form.value.el$('email').messageBag.append($t('email-in-use'));
            break;
          case 132014:
          case 153009:
            focusInputField('idNumber');
            form.value
              .el$('idNumber')
              .messageBag.append($t('id-already-exists'));
            break;
          case 132036:
            focusInputField('idNumber');
            form.value.el$('idNumber').messageBag.append($t('id-invalid'));
            break;
          default:
            console.error(error?.code);
            break;
        }
      }
    })
    .catch((e) => {
      formError.value = $t('registration-failed');
      console.error(e);
      if (e === 'deceased-or-minor-reg') {
        formError.value = $t(e);
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
}

//on Next page validate sourceOfFunds to throw error and vali ID type to ensure page 2 is valid to finish reg
function sourceOfFundsError() {
  if (form?.value?.el$('sourceOfFunds'))
    form.value.el$('sourceOfFunds').validate();
  if (form?.value?.el$('idNumberType'))
    form.value.el$('idNumberType').validate();
}

//return boolean if property is on current page
function isActiveField(propertyName: string) {
  const propertyPageNo = vfPageOne.value.includes(propertyName) ? 0 : 1; //find page number the propertyu belongs to then subtract by 1 to account for vueform numbering
  return formSteps?.value?.current$?.index === propertyPageNo;
}

function toggleReferralInput() {
  isReferralInputActive.value = !isReferralInputActive.value;
  trackingMethods.registrationToggleReferralInput();
}

onBeforeMount(async () => {
  formLoading.value = true;
  const { templateSections } = await $registrationService.fetchTemplate();
  formatTemplate(templateSections);
  formLoading.value = false;
  // removing both pseudo input elements to prevent user manipulation
  //setTimeout to allow google chrome to autofill
  setTimeout(() => {
    const elements = document.getElementsByName('removePseudo');
    [...elements].forEach((el) => {
      el.remove();
    });
  }, 500);

  nextTick(() => {
    progressBarFields.value = { ...form?.value?.elements$ };
    //delete not required fields & delete idType bc it has been preset and is valid
    delete progressBarFields?.value['receivePromotionalInformation'];
    delete progressBarFields?.value['idNumberType'];
    delete progressBarFields?.value['referralCode'];
    delete progressBarFields?.value['welcomeOffer'];
    delete progressBarFields?.value['agreeToAll'];
    delete progressBarFields?.value['language'];

    if (form?.value?.el$('language')) form.value.el$('language').validate();
    if (
      !!route.query?.signupcode ||
      !!$storageService.getCookie({ key: 'signup-code' })
    ) {
      toggleReferralInput();
      form.value.load({
        referralCode:
          route.query?.signupcode ||
          $storageService.getCookie({ key: 'signup-code' }),
      });
    }
  });
  trackingMethods.registerLoaded();
});

function trimInput(element: string) {
  form?.value?.elements$[element]?.update(form?.value?.data[element]?.trim());
}

//specifically named function to match reg template
function agreeToAll() {
  setTimeout(() => {
    const flag = form?.value?.el$('agreeToAll')?.data?.agreeToAll;
    Object.entries(form?.value?.elements$).forEach((element) => {
      if (element[1]?.type === 'checkbox') {
        element[1]?.update(flag);
      }
    });
  }, 100);
}
</script>
<template>
  <div>
    <div class="w-full flex px-3 rounded-lg mt-2 h-[6px]">
      <progress
        id="progressBar"
        :value="progressBar"
        class="rounded-lg w-full"
      />
    </div>
    <div class="reg-vueform-container min-h-80">
      <Vueform
        v-if="formattedTemplate.length > 1"
        ref="form"
        :float-placeholders="true"
        :endpoint="false"
        :messages="{
          email: $t('formErrors.email'),
        }"
      >
        <template #empty>
          <div class="mx-3 my-2">
            <FormSteps
              ref="formSteps"
              @next="
                (step) => {
                  sourceOfFundsError();
                }
              "
            >
              <FormStep ref="pageOne" name="acc" :elements="vfPageOne" />
              <FormStep
                v-if="vfPageTwo?.length > 0"
                ref="pageTwo"
                name="veri"
                :elements="vfPageTwo"
              />
            </FormSteps>
            <FormElements class="!grid-cols-1 !gap-2">
              <input
                id="username"
                type="email"
                style="opacity: 0; position: absolute"
                name="removePseudo"
              />
              <input
                type="password"
                style="opacity: 0; position: absolute"
                name="removePseudo"
              />
              <div
                v-for="(el, index) in formattedTemplate"
                :key="index"
                :class="isActiveField(el.name) ? 'vf-col-12' : 'hidden'"
              >
                <TextElement
                  v-if="el.element === 'mobileNumber'"
                  :key="el.name"
                  :name="el.name"
                  :input-type="el?.inputMode"
                  :attrs="{ inputmode: 'numeric' }"
                  :placeholder="$t('placeholder.' + el.name)"
                  :floating="el.label"
                  :field-name="el.label"
                  :rules="el?.rules"
                  :messages="el?.messages"
                  @change="form.el$('primaryMobileNumber').clearMessages()"
                >
                  <template #addon-before>
                    <div
                      class="bg-light-200 dark:bg-dark-800 flex flex-row justify-center items-center border-r border-solid std-border-color w-full h-full px-2"
                    >
                      {{ site.getDialingCode }}
                    </div>
                  </template>
                </TextElement>
                <TextElement
                  v-if="el.element === 'inputField'"
                  :key="el.name"
                  :ref="el.name === 'idNumber' ? 'idNumber' : ''"
                  :name="el.name"
                  :input-type="el?.inputMode"
                  :placeholder="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : $t('placeholder.' + el.name)
                  "
                  :floating="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : el.label
                  "
                  :field-name="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : ''
                  "
                  :rules="el.name === 'idNumber' ? 'required' : el?.rules"
                  :messages="el?.messages"
                  @change="
                    el.name === 'idNumber'
                      ? validateIdField()
                      : form?.value?.el$(el.name)?.clearMessages()
                  "
                  @blur="trimInput(el.name)"
                />
                <TextElement
                  v-if="el.element === 'email'"
                  :key="el.name"
                  :name="el.name"
                  :input-type="el?.inputMode"
                  :placeholder="$t('placeholder.' + el.name)"
                  :floating="el.label"
                  :field-name="el.label"
                  :rules="el?.rules"
                  :messages="el?.messages"
                  @change="form.el$(el.name).clearMessages()"
                />
                <VueformPasswordElement
                  v-if="el.element === 'password'"
                  :key="el.name"
                  :name="el.name"
                  :rules="el?.rules"
                  :messages="el?.messages"
                  :placeholder="$t('placeholder.' + el.name)"
                  :floating="el.label"
                  :field-name="el.label"
                  display-feedback
                />
                <SelectElement
                  v-if="el.element === 'dropdown'"
                  :ref="el.name === 'idNumberType' ? 'idNumberType' : ''"
                  :key="el.name"
                  :name="el.name"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  add-class="vf-select-element"
                  :items="el.items"
                  :default="
                    el.name === 'idNumberType'
                      ? 'IdNumber'
                      : el.name === 'language'
                        ? 'en-US'
                        : ''
                  "
                  :placeholder="
                    el.name === 'sourceOfFunds'
                      ? $t('placeholder.' + el.name)
                      : ''
                  "
                  :field-name="el.label"
                  :floating="
                    el.name === 'sourceOfFunds' || el.name === 'language'
                      ? el.label
                      : ''
                  "
                  :rules="el.rules"
                  @change="el.name === 'idNumberType' ? changeIdType() : ''"
                />

                <VueformCalendarElement
                  v-if="el.element === 'dob'"
                  :key="el.name"
                  ref="dateOfBirth"
                  :name="el.name"
                  :rules="['required', $underageCheck]"
                  :disable-cal="bdayLock"
                  :max-cal-date="minAge"
                  :min-cal-date="maxAge"
                >
                  <template #icon>
                    <component :is="calendarIcon" :key="el.name" />
                  </template>
                </VueformCalendarElement>

                <CheckboxElement
                  v-if="el.element === 'checkbox'"
                  :key="el.name"
                  align="left"
                  :name="el.name"
                  :text="el.label"
                  :rules="el.name === 'terms' ? ['required'] : ''"
                  :messages="
                    el.name === 'terms'
                      ? { required: $t('formErrors.terms') }
                      : {}
                  "
                  class="text-base-priority"
                  @click="el?.clickFunction"
                />
                <div v-if="el.element === 'dropdownInput'">
                  <div
                    class="flex justify-between items-center w-full mb-2 p-3 cursor-pointer input-style std-border rounded-lg"
                    @click="toggleReferralInput()"
                  >
                    {{ $t('have-signup-code') }}
                    <component
                      :is="
                        isReferralInputActive ? ChevronUpIcon : ChevronDownIcon
                      "
                      :key="String(isReferralInputActive)"
                      class="w-4"
                    />
                  </div>
                  <TextElement
                    v-if="isReferralInputActive"
                    :key="el.name"
                    :name="el.name"
                    :placeholder="$t(`placeholder.${el.name}`)"
                    :floating="el.label"
                  />
                </div>

                <VueformRadioElement
                  v-if="el.element === 'radioList'"
                  :key="el.name"
                  :name="el.name"
                  :items="el.items"
                  :header-label="$t(`label.${el.name}`)"
                  :default-value="el?.defaultVal"
                />
              </div>
              <div
                v-if="
                  form?.steps$?.isAtLastStep &&
                  $enabled('website.enablerecaptcha')
                "
                class="mb-2 flex-center"
              >
                <CaptchaElement
                  provider="recaptcha2"
                  name="captcha"
                  :default="captcha[site.getRegionCode]"
                  :is-required="true"
                  should-verify
                  :options="{
                    sitekey: captcha[site.getRegionCode],
                    theme: colorMode.preference === 'dark' ? 'dark' : 'light',
                  }"
                />
              </div>
            </FormElements>
            <GenericError
              v-if="formError"
              class="mt-3 max-w-72 mx-auto"
              state="danger"
            >
              {{ formError }}
            </GenericError>
          </div>
          <div class="bg-layer-1 w-full p-4">
            <div
              v-if="form?.steps$?.isAtFirstStep"
              class="rounded-lg flex justify-between items-center px-2 w-full bg-base text-xs mb-2"
            >
              <p class="my-3 dark:text-white text-black font-normal">
                {{ $t('already-have-account') }}
              </p>
              <div class="flex flex-row flex-nowrap items-center">
                <p
                  class="mt-2.5 mb-2.5 mr-1 dark:text-primary-blue-100 text-primary-blue-300 text-xs flex flex-row cursor-pointer font-normal"
                  @click="site.activateModal('login')"
                >
                  {{ $t('login') }}
                </p>
                <ChevronRightIcon
                  class="h-4 text-xs dark:text-primary-blue-100 text-primary-blue-300 font-light"
                />
              </div>
            </div>

            <Button
              element-name="step-one-next-registration"
              v-if="!form?.steps$?.isAtLastStep"
              :disabled="!(pageOne?.validated && !pageOne?.invalid)"
              type="primary"
              class="w-full justify-center"
              @click="formSteps.next()"
            >
              {{ $t('next') }}
            </Button>
            <div
              v-if="form?.steps$?.isAtLastStep"
              class="last-page-controls flex gap-4"
            >
              <Button
                v-if="!form?.steps$?.isAtFirstStep"
                element-name="step-two-previous-registration"
                type="tertiary-alternative"
                class="w-full justify-center dark:!bg-dark-700"
                @click="formSteps.previous()"
              >
                {{ $t('previous') }}
              </Button>
              <Button
                element-name="jpc-register"
                group="primary"
                class="w-full justify-center"
                :disabled="
                  !!pageTwo
                    ? !(pageTwo?.validated && !pageTwo?.invalid)
                    : !(pageOne?.validated && !pageOne?.invalid)
                "
                @click="register()"
              >
                {{ $t('jpc-register') }}
              </Button>
            </div>
          </div>
        </template>
      </Vueform>
    </div>
    <LazyUserInterfaceGenericLoader v-if="formLoading" container />
  </div>
</template>

<style lang="scss">
progress {
  height: 100%;
  background: #f0f0f2;
  .dark & {
    background: #202126;
  }
  &::-webkit-progress-value {
    background-image: linear-gradient(88deg, #2530ac 0%, #3879fb 100%);
    height: 5px;
    border-radius: 8px;
    -webkit-transition: width 1s ease;
    -moz-transition: width 1s ease;
    -o-transition: width 1s ease;
    transition: width 1s ease-out;
  }
  &::-webkit-progress-bar {
    background: #202126;
    border-radius: 8px;
  }
}

.reg-vueform-container .form-steps {
  display: none;
}

input:-webkit-autofill {
  caret-color: #3879fb;
}

input {
  caret-color: #3879fb;
}

.p-inputtext,
input:-internal-autofill-selected,
input:-webkit-autofill {
  background-color: #f0f0f2;
  .dark & {
    color: #ffffff !important;
    background-color: #201d29;
  }
}

.form-color-input-danger {
  border-color: #f50000 !important;
  background-clip: content-box !important; //remove weird greyish corners on autofill inputs
}

//Vueform autofill components
.form-autofill-success:-webkit-autofill,
.form-autofill-success:-webkit-autofill:active,
.form-autofill-success:-webkit-autofill:focus,
.form-autofill-success:-webkit-autofill:hover {
  .reg-vueform-container & {
    -webkit-box-shadow: 0 0 0 99px #f0f0f2 inset !important;
    .dark & {
      -webkit-box-shadow: 0 0 0 99px #202126 inset !important;
    }
    background-clip: content-box !important; //remove weird greyish corners on autofill inputs
    & .form-color-input-danger {
      border-color: #f50000 !important;
      background-clip: content-box !important; //remove weird greyish corners on autofill inputs
    }
  }
}

//Our custom components autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  .reg-vueform-container & {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #2f2f37;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #f0f0f2 !important;
    .dark & {
      -webkit-text-fill-color: #ffffff;
      box-shadow: inset 0 0 20px 20px #202126 !important;
    }
  }
}

.form-border-color-input-success:focus-within {
  border-color: #3879fb !important;
}

.input--password-field:focus,
.input--password-field:hover {
  border-color: #3879fb !important;
}

.form-pl-input {
  padding-left: 0px;
}

.p-datepicker-select-year {
  pointer-events: none;
}

.input--password-field.focus\:ring-1 {
  box-shadow: none;
}

.form-bg-checkbox {
  @apply dark:bg-dark-900;
  &:hover,
  &:visited,
  &:focus {
    @apply dark:bg-dark-900;
  }
  &-danger {
    @apply dark:bg-dark-900 border-error-500;
  }
}

.reg-vueform-container input.bg-transparent {
  @apply bg-light-200 dark:bg-dark-800;
}

.reg-vueform-container input[type='checkbox']:hover,
input[type='checkbox']:focus {
  box-shadow: none;
}

// Dropdown styling

div#sourceOfFunds,
div#idNumberType,
div#language {
  @apply border-[1px] border-light-400 dark:border-dark-600 rounded-lg;
  & div:first-child {
    padding-left: 14px;

    & div.with-floating\:form-p-input-floating {
      @apply form-py-input;
    }
  }
  & span.mask-form-caret {
    mask-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' width='24' height='24' viewBox='0 0 24 24'><path d='M6.35147 8.7515C6.8201 8.28287 7.5799 8.28287 8.04853 8.7515L12 12.703L15.9515 8.7515C16.4201 8.28287 17.1799 8.28287 17.6485 8.7515C18.1172 9.22013 18.1172 9.97992 17.6485 10.4486L12.8485 15.2486C12.3799 15.7172 11.6201 15.7172 11.1515 15.2486L6.35147 10.4486C5.88284 9.97992 5.88284 9.22013 6.35147 8.7515Z' fill='currentColor' /></svg>");
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

div#sourceOfFunds-dropdown,
div#idNumberType-dropdown,
div#language-dropdown {
  & ul {
    @apply border-[1px] border-light-400 dark:border-dark-600 rounded-b-lg;
    & li {
      @apply px-0 py-1 pl-3;
      &:hover:not(.form-bg-primary) {
        @apply bg-light-100 dark:bg-dark-900;
      }
    }
  }
}

// Float label source of income

.vf-select-element .col-span-12 .label-floating {
  margin-top: 16px;
  & span {
    font-size: 12px;
    background: none;
    top: -12px;
    left: 0px;
    margin-top: 0;
  }
}
</style>
