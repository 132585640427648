<script lang="ts" setup>
interface IButtonProps {
  type?:
    | 'primary'
    | 'primary-alternative'
    | 'secondary'
    | 'secondary-alternative'
    | 'tertiary'
    | 'tertiary-alternative'
    | 'neutral'
    | 'other'
    | 'transparent'
    | 'quarternary';
  padding?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'wide' | 'wide-short' | 'tall';
  rounding?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  shape?: 'circle' | 'square';
  icon?: boolean;
  class?: string | Record<string, boolean>;
  disabled?: boolean;
}

const props = withDefaults(defineProps<IButtonProps>(), {
  icon: false,
  class: '',
  disabled: false,
});

const defaults =
  'flex items-center truncate font-bold hover:cursor-pointer hover:shadow-md';

const color = computed(() => {
  if (props?.disabled) return 'disabled text-base-disabled';
  const defaultColors =
    'bg-primary-blue-gradient text-white hover:shadow-blue-500/60';
  if (!!props.type) {
    switch (props.type) {
      case 'primary':
        return defaultColors;
      case 'primary-alternative':
        return 'bg-primary-pink-gradient text-white hover:shadow-pink-900/60';
      case 'secondary':
        return 'bg-light-200 dark:bg-dark-700 text-base-priority';
      case 'secondary-alternative':
        return 'bg-light-50 font-bold text-dark-700 dark:bg-dark-700 dark:text-light-50';
      case 'tertiary':
        return 'bg-base text-base-priority';
      case 'tertiary-alternative':
        return 'bg-primary-layer text-base-priority';
      case 'neutral':
        return 'bg-neutral-gradient dark:text-white';
      case 'other':
        return 'bg-gold-gradient text-white hover:shadow-gold-500/60';
      case 'transparent':
        return 'text-base-priority';
      case 'quarternary':
        return 'bg-layer-3 text-base-priority';
    }
  }
  return defaultColors;
});

const padding = computed(() => {
  if (!!props.padding) {
    switch (props.padding) {
      case 'xs':
        return 'p-0.5';
      case 'sm':
        return 'p-1';
      case 'md':
        return 'p-2';
      case 'lg':
        return 'p-3';
      case 'xl':
        return 'p-4';
      case 'wide':
        return 'py-2 px-3';
      case 'wide-short':
        return 'py-1 px-3';
      case 'tall':
        return 'px-3 py-2';
    }
  }
  return 'p-2';
});

const rounding = computed(() => {
  if (props.shape === 'circle') return '';
  if (!!props.rounding) {
    switch (props.rounding) {
      case 'xs':
        return 'rounded-xs';
      case 'sm':
        return 'rounded-sm';
      case 'md':
        return 'rounded-md';
      case 'lg':
        return 'rounded-lg';
      case 'xl':
        return 'rounded-xl';
    }
  }
  return 'rounded-xl';
});

const shape = computed(() => {
  if (!!props.shape) {
    switch (props.shape) {
      case 'circle':
        return 'rounded-full aspect-square';
      case 'square':
        return 'aspect-square';
    }
  }
  return '';
});

const generatedClasses = computed(() => {
  let newDefaults = defaults;
  if (props.class.includes('hidden')) {
    newDefaults = newDefaults.replace('flex', '');
  }
  return `design-system-button ${newDefaults} ${color.value} ${padding.value} ${rounding.value} ${shape.value} ${props.class}`;
});
</script>

<template>
  <slot v-if="!$slots.override" name="override" v-bind="$attrs" />
  <button
    v-if="!$slots.override"
    v-bind="$attrs"
    v-bind:class="generatedClasses"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
.disabled {
  @apply pointer-events-none cursor-not-allowed bg-dark-400;
}
</style>
