// eslint-disable-next-line
import { Analytics } from '@analytics/core';
import googleTagManager from '@analytics/google-tag-manager';
import AnalyticsEnums from './analytics/enums';
import { NavigationEvent } from '~/plugins/analytics/enums/NavigationEventEnum';

type PageData = {
  title?: string;
  url?: string;
  path?: string;
  search?: string;
  width?: string;
  height?: string;
};

/* eslint-disable */
interface TrackingVariables {
  name: string;
  link_url?: string;
  link_action?: string;
  channel?: string;
  banner_tag?: string;
  player_id?: string;
  master_token?: string;
  session_token?: string;
}
/* eslint-enable */
export let trackingMethods;
export default defineNuxtPlugin(() => {
  const analyticsClient = Analytics({
    app: 'jackpot-city',
    version: '1',
    plugins: [googleTagManager({ containerId: 'GTM-59D9F64' })],
    debug: true,
  });
  const route = useRoute();
  trackingMethods = {
    loadPage: (params: PageData) => analyticsClient?.page(params),
    trackEvent: (eventName: string, params?: unknown) => {
      return analyticsClient?.track(eventName, params);
    },
    identifyUser: (userId: string, userInfo?: unknown) =>
      analyticsClient?.identify(userId, userInfo),

    // ===================== Account
    // Login Tracking Methods
    login: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_INIT, {
        msisdn,
      }),
    loginComplete: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_SUCCESS, {
        msisdn,
      }),
    loginFailure: (msisdn: string, reason: any) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_FAILURE, {
        msisdn,
        reason,
      }),

    // Registration Tracking Methods
    register: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.RegistrationEvent.REG_INIT, {
        msisdn,
      }),
    registerLoaded: () => {
      return trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_PAGE_LOADED,
      );
    },
    registrationToggleReferralInput: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_TOGGLE_REFERRAL_INPUT,
      ),
    registerPersonalComplete: (msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.PERSONAL_DETAILS_COMPLETED,
        {
          msisdn,
        },
      ),
    registerComplete: (msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_COMPLETED,
        {
          msisdn,
        },
      ),
    registerFailure: (msisdn: string, reason: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.RegistrationEvent.REG_FAILURE, {
        msisdn,
        reason,
      }),
    registerAbandoned: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_ABANDONED,
      ),

    // Account Page tracking methods
    myAccountBonusWallet: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_WALLET,
      ),
    myAccountBonusWalletHowTo: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_WALLET_HOW_TO,
      ),
    myAccountBonusWalletRefresh: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_WALLET_REFRESH,
      ),
    myAccountBonusWalletForfeit: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_WALLET_FORFEIT,
      ),
    myAccountBonusSummary: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_SUMMARY_INIT,
      ),
    myAccountBonusSummaryCompleted: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_SUMMARY_COMPLETED,
      ),
    myAccountBonusSummaryFailure: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_SUMMARY_FAILURE,
      ),
    myAccountBonusSummarySearch: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_SUMMARY_SEARCH,
      ),
    myAccountBonusSummaryDetails: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_BONUS_SUMMARY_DETAILS,
      ),
    myAccountDetailedTransaction: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_DETAILED_TRANSACTION,
      ),
    myAccountHistoryTransactionRefresh: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_HISTORY_TRANSACTION_REFRESH,
      ),
    myAccountTeller: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_TELLER),
    myAccountHistory: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_HISTORY),
    myAccountSettings: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_SETTINGS),
    myAccountLogout: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_LOGOUT),
    myAccountDetailsUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_DETAILS_UPDATE_ACTION,
      ),
    myAccountPasswordUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_PASSWORD_UPDATE_ACTION,
      ),
    myAccountSettingsUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_SETTINGS_UPDATE_ACTION,
      ),
    doc_verification_submit: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.DOC_VERIFICATION_SUBMIT,
      ),
    doc_verification_error: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.DOC_VERIFICATION_ERROR),

    // ===================== Navigation
    // Search and Filter Methods
    filter: (category: any, provider: any, theme: any) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.FILTER_GAMES, {
        category,
        provider,
        theme,
      }),
    toggleSearch: () =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.TOGGLE_SEARCH),
    searchQuery: (searchQuery: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH, {
        searchQuery,
      }),
    searchQueryEmpty: () =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH_EMPTY),
    searchFailure: (searchFailure: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH_FAILURE, {
        searchFailure,
      }),
    searchResultSuccess: (searchResult: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.SearchEvent.SEARCH_RESULT_SUCCESS,
        { searchResult },
      ),

    // Route Changes
    routeUnavailable: () => trackingMethods.loadPage({ title: '404' }),
    routeChange: (to: string, from: string) =>
      trackingMethods.trackEvent('RouteChange', { to, from }),
    headerClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.HEADER_LINK_CLICK,
      ),
    footerClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.FOOTER_LINK_CLICK,
      ),
    goToWinners: () =>
      trackingMethods.trackEvent(NavigationEvent.GO_TO_WINNERS),

    // User Action events
    toggleHamburgerMenu: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.OPEN_HAMBURGER_MENU,
      ),
    liveChatInit: () =>
      trackingMethods.trackEvent(AnalyticsEnums.LiveChatEvent.LIVE_CHAT_INIT),
    liveChatClosed: () =>
      trackingMethods.trackEvent(AnalyticsEnums.LiveChatEvent.LIVE_CHAT_CLOSED),
    bannerClicked: (args: unknown) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.BannerEvent.BANNER_CTA_CLICK,
        args,
      ),
    bannerInfoClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.BannerEvent.BANNER_MORE_INFO_CTA_CLICK,
      ),
    subbannerClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.BannerEvent.SUBBANNER_CTA_CLICK,
      ),

    // Contact Us
    contactUs: () =>
      trackingMethods.trackEvent(AnalyticsEnums.ContactUs.CONTACT_US_CTA_CLICK),

    // Promotion
    PromotionInit: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Promotion.PROMO_CTA_CLICK),
    PromotionCompleted: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Promotion.PROMO_CTA_CLICK_COMPLETED,
      ),
    PromotionFailure: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Promotion.PROMO_CTA_CLICK_FAILURE,
      ),
    PromotionMoreInfoClick: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Promotion.PROMO_CTA_MORE_INFO_CLICK,
      ),
    PromotionDetermineActionClick: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Promotion.PROMO_CTA_DETERMINE_ACTION_CLICK,
      ),

    // Notification Events
    MyNotificationsActions: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Notification.NOTIFICATION_ACTIONS,
      ),
    MyNotificationsClosePanel: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Notification.NOTIFICATION_CLOSE_PANEL,
      ),
    MyNotificationsCount: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Notification.NOTIFICATION_COUNT,
      ),

    // Games Events
    launchReal: (game: string, msisdn: string, component?: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LAUNCH_REAL_GAME, {
        game,
        msisdn,
        path: route.fullPath,
        component,
      }),
    launchDemo: () =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LAUNCH_DEMO_GAME),
    likeGame: (game: string, likes: number) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LIKE_GAME, {
        game,
        likes,
      }),
    dislikeGame: (game: string, likes: number) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.DISLIKE_GAME, {
        game,
        likes,
      }),
    favouriteGameAdd: (game: string, msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.FAVOURITE_GAME_ADD, {
        game,
        msisdn,
      }),
    favouriteGameRemove: (game: string, msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.GameEvent.FAVOURITE_GAME_REMOVE,
        {
          game,
          msisdn,
        },
      ),
    socialShare: (game: string, msisdn: string, channel: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.SOCIAL_SHARE, {
        game,
        msisdn,
        channel,
      }),

    // ===================== Site Settings
    toggleLanguage: (locale: string) =>
      trackingMethods.trackEvent('SwitchLanguage', { locale }),
  };
  addRouteMiddleware(
    'route-change',
    (to, from) => {
      trackingMethods?.routeChange(to.path, from.path);
      const action = window?.dtrum?.enterAction(
        `navigate`,
        null,
        null,
        to.fullPath,
      );
      window?.dtrum?.leaveAction(action);
      switch (to.name) {
        case '404':
          trackingMethods?.routeUnavailable();
          break;
      }
    },
    { global: true },
  );
});
