import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';

export const useFormatCurrency = (value: number | bigint, truncated?: boolean): string => {
  const localeStore = useLocaleStore();
  const siteStore = useSiteStore();
  const notationValue = value > 999 && truncated ? 'compact' : 'standard';
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    style: 'currency',
    currency: siteStore.getCurrencyCode,
    currencyDisplay: 'narrowSymbol',
    notation: notationValue,
  });
  return formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return `<strong class="gold-gradient-text">${value}</strong>`;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
};

export const useFormatNumberCompact = (value: number): string => {
  const localeStore = useLocaleStore();
  const siteStore = useSiteStore();
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    notation: 'compact',
  });
  return formatter.format(value);
};
