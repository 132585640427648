<script setup lang="ts">
import DefaultLayout from './components/layout/default.vue';
const settings = useSiteStore();
const loading = ref<boolean>(true);
onMounted(() => (loading.value = false));
</script>
<template>
  <DefaultLayout v-if="!settings.isWebview">
    <LazyUserInterfaceGenericLoader
      v-if="settings.isFullscreenLoading ?? loading"
      full-screen
    />
    <NuxtPage />
  </DefaultLayout>
  <NuxtPage v-else />
</template>
